<template>
    <div class="eCommerce-container">
        <div class="tab-top" v-if="$route.query.method === 'stuTrain' || $route.query.method === 'teaTrain'">
            <el-breadcrumb separator=">" class="genera-breadcrumb" style="padding: 16px 0 0px">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="back-btn" @click="goBack" type="primary">返回</el-button>
        </div>
        <template v-if="moduleId === 22">
            <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                <el-table-column prop="exam_module" label="考试模块"></el-table-column>
                <el-table-column label="学生提交答案">
                    <template slot-scope="scope">
                        <el-link
                            v-if="scope.row.stu_answer === ''"
                            type="primary"
                            :underline="false"
                            @click="detailBtn($route.query.method, $route.query.trainId, 'stuAnswer')"
                            >查看详情</el-link
                        >
                        <div v-else-if="scope.row.stu_answer === null">暂无回答</div>
                        <div class="goods-info" v-else>
                            <div class="good-img">
                                <img :src="scope.row.stu_answer.good_images" alt="" />
                            </div>
                            <div class="info-right text-overflow-2">{{ scope.row.stu_answer.good_title }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="正确答案" v-if="$route.query.method !== 'stuTrain'">
                    <template slot-scope="scope">
                        <el-link
                            v-if="scope.row.answer.hasOwnProperty('id')"
                            type="primary"
                            :underline="false"
                            @click="detailBtn($route.query.method, scope.row.answer.id, 'correctAnswer')"
                            >查看详情</el-link
                        >
                        <div class="goods-info" v-else>
                            <div class="good-img">
                                <img :src="scope.row.answer.good_images" alt="" />
                            </div>
                            <div class="info-right text-overflow-2">{{ scope.row.answer.good_title }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center">
                    <template #header>得分（{{ infoScore ? infoScore : 0 }}分）</template>
                    <template slot-scope="scope">
                        {{ scope.row.score ? scope.row.score : 0 }}
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else-if="moduleId === 23">
            <el-table :data="cleanList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column prop="exam_content" label="考试内容"></el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <el-link type="primary" :underline="false" @click="cleanDetail($route.query.method, 2, 'stuAnswer')"
                        >查看详情</el-link
                    >
                </el-table-column>
                <el-table-column label="正确答案" align="center" v-if="$route.query.method !== 'stuTrain'">
                    <el-link type="primary" :underline="false" @click="cleanDetail($route.query.method, 1, 'correctAnswer')"
                        >查看详情</el-link
                    >
                </el-table-column>
                <el-table-column align="center">
                    <template #header>得分（{{ cleanScore ? cleanScore : 0 }}分）</template>
                    <template slot-scope="scope">
                        {{ scope.row.score ? scope.row.score : 0 }}
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>

<script>
import { stuOpTrainData, teacheropTrainData, teacheropStoreCustomerServiceDetail } from "@/utils/apis.js";
export default {
    data() {
        return {
            // moduleId: Number(this.$route.query.moduleId) || null,
            moduleId: null,
            infoList: [],
            infoScore: null,
            cleanList: [],
            cleanScore: null,
            role: localStorage.getItem("role"),
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getInfo() {
            let params = {};
            let res;
            if (this.$route.query.method === "stuTrain") {
                // 学生端训练中心
                params.train_id = this.$route.query.trainId;
                res = await stuOpTrainData(params);
            } else if (this.$route.query.method === "teaTrain") {
                // 教师端训练中心
                params.train_id = this.$route.query.trainId;
                res = await teacheropTrainData(params);
            } else if (this.$route.query.method === "teaExam" || this.$route.query.method === "teaExamTrain") {
                // 教师端考试中心 考试数据、实训数据
                params.exam_id = this.$route.query.examId;
                params.student_id = this.$route.query.id;
                res = await teacheropStoreCustomerServiceDetail(params);
            }
            this.$nextTick(() => {
                if (
                    (res.data.hasOwnProperty("data_collect") && res.data.data_collect[0].exam_content === "数据采集") ||
                    Number(res.data) === 22
                ) {
                    this.moduleId = 22;
                }
                if (
                    (res.data.hasOwnProperty("clean") && res.data.clean.exam_content === "电子商务数据清洗") ||
                    Number(res.data) === 23
                ) {
                    this.moduleId = 23;
                }
                if (this.moduleId === 22) {
                    if (Object.prototype.toString.call(res.data) === "[object Object]") {
                        for (const key in res.data.data_collect) {
                            if (Object.prototype.toString.call(res.data.data_collect[key]) === "[object Object]") {
                                let tmp = {
                                    exam_content: res.data.data_collect[key].exam_content,
                                    exam_module: res.data.data_collect[key].exam_module,
                                    stu_answer: res.data.data_collect[key].hasOwnProperty("stu_answer")
                                        ? res.data.data_collect[key].stu_answer
                                        : "",
                                    // score: res.data.data_collect[key].hasOwnProperty('score') ? res.data.data_collect[key].score : res.data.data_collect[key].stu_answer.score,
                                    score: res.data.data_collect[key].hasOwnProperty("score")
                                        ? res.data.data_collect[key].score
                                        : Object.prototype.toString.call(res.data.data_collect[key].stu_answer) === "[object Object]"
                                        ? res.data.data_collect[key].stu_answer.score
                                        : "",
                                };
                                if (this.$route.query.method !== "stuTrain") {
                                    tmp.answer = res.data.data_collect[key].answer;
                                }
                                this.infoList.push(tmp);
                            }
                        }
                    } else {
                        this.infoList = [];
                    }
                    if (res.data.data_collect) {
                        let stu_score = 0;
                        for (let key in res.data.data_collect) {
                            if (res.data.data_collect[key].exam_module) {
                                let stu_answer = res.data.data_collect[key].stu_answer;
                                if (stu_answer) {
                                    stu_score += Number(stu_answer.score);
                                }
                                if (res.data.data_collect[key].score) {
                                    stu_score += res.data.data_collect[key].score;
                                }
                            }
                        }
                        this.infoScore = stu_score;
                    }
                }
                if (this.moduleId === 23) {
                    if (Object.prototype.toString.call(res.data) === "[object Object]") {
                        let tmp = {
                            exam_content: res.data.clean.exam_content,
                            score: res.data.clean.score,
                        };
                        this.cleanList.push(tmp);
                        // this.cleanScore = res.data.clean.total_score
                        this.cleanScore = res.data.clean.score;
                    } else {
                        this.cleanList = [];
                    }
                }
            });
        },
        detailBtn(method, id, type) {
            if (method === "stuTrain") {
                // 学生端训练中心
                this.$router.push({
                    path: "/student/trainCenter/eCommerceCollectDetail",
                    query: {
                        trainId: id,
                        method: method,
                        type: type,
                    },
                });
            }
            if (method === "teaTrain") {
                // 教师端训练中心
                this.$router.push({
                    path: "/trainingCenter/practicalTraining/eCommerceCollectDetail",
                    query: {
                        trainId: id,
                        method: method,
                        type: type,
                    },
                });
            }
            
            if (method === "teaExam") {
                // 教师端考试中心 考试数据
                this.$router.push({
                    path: this.role == 4 ? "/jd/judgesCenter/examData/eCommerceCollectDetail" : "/jd/examCenter/examData/eCommerceCollectDetail",
                    // path: "/jd/examCenter/examData/eCommerceCollectDetail",
                    query: {
                        trainId: id,
                        examId: this.$route.query.examId,
                        studentId: this.$route.query.id,
                        method: method,
                        type: type,
                    },
                });
            }
            if (method === "teaExamTrain") {
                // 教师端考试中心 实训数据
                this.$router.push({
                    path: "/examCenter/trainingData/eCommerceCollectDetail",
                    query: {
                        trainId: id,
                        examId: this.$route.query.examId,
                        studentId: this.$route.query.id,
                        method: method,
                        type: type,
                    },
                });
            }
        },
        cleanDetail(method, submitType, type) {
            if (method === "stuTrain") {
                // 学生端训练中心
                this.$router.push({
                    path: "/student/trainCenter/eCommerceCleanDetail",
                    query: {
                        method: method,
                        submitType: submitType,
                        trainId: this.$route.query.trainId,
                    },
                });
            }
            if (method === "teaTrain") {
                // 教师端训练中心
                this.$router.push({
                    path: "/trainingCenter/practicalTraining/eCommerceCleanDetail",
                    query: {
                        method: method,
                        submitType: submitType,
                        trainId: this.$route.query.trainId,
                    },
                });
            }
            if (method === "teaExam") {
                // 教师端考试中心 考试数据
                this.$router.push({
                    path: "/examManage/examData/eCommerceDetail",
                    query: {
                        method: method,
                        submitType: submitType,
                        // trainId: this.$route.query.trainId,
                        examId: this.$route.query.examId,
                        studentId: this.$route.query.id,
                    },
                });
            }
            if (method === "teaExamTrain") {
                // 教师端考试中心 实训数据
                this.$router.push({
                    path: "/examCenter/trainingData/eCommerceCleanDetail",
                    query: {
                        method: method,
                        submitType: submitType,
                        // trainId: this.$route.query.trainId,
                        examId: this.$route.query.examId,
                        studentId: this.$route.query.id,
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.tab-top {
    position: relative;
    .back-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.goods-info {
    display: flex;
    align-items: center;
    .good-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 10px;
    }
}
</style>
